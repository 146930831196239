import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import GridItem from '../../../components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import styles from './styles/littlePlanetFraternityStyles'

const useStyles = makeStyles(styles)

const LittlePlanetFraternity = () => {
	const classes = useStyles()
	const { t } = useTranslation('global')
	const handleImageClick = () => {
		window.open('/assets/files/home/my-passport-en.pdf', '_blank')
	}
	return (
		<>
			<Grid container className={classes.sectionLittlePlanetFraternity}>
				<GridItem xs={12} sm={12} md={12}>
					<div className={classes.little}>Little</div>
					<p className={classes.title}>Planet Fraternity</p>
					<img alt='oneOne' src={'/assets/img/screenLittle/heart.webp'} className={classes.heart} />
					<p className={classes.team}>{t('layout.t_a-project-for')}</p>
					<p className={classes.text}>{t('layout.t_we_sow_the_seed_of_fraternity')}</p>
				</GridItem>

				<GridItem xs={12} sm={12} md={12}>
					<div className={classes.containerImages}>
						<img alt='oneOne' src={'/assets/img/screenLittle/world.png'} className={classes.image} />
						<a href='#' onClick={handleImageClick}>
							<img
								alt='oneOne'
								src={'/assets/img/screenLittle/image-little-planet-fraternity.png'}
								className={classes.imageLittlePlanetFraternity}
							/>
						</a>
					</div>
				</GridItem>
			</Grid>
		</>
	)
}

export default LittlePlanetFraternity
